import React from "react";
import "../../../pages/DrawerContent.css";
import ProjectsFilter from "./ProjectsFilter";
import ProjectsList from "./ProjectsList";

export default function ProjectsPage() {
   return (
      <div>
         <ProjectsFilter />
         <ProjectsList />
      </div>
   );
}
