import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UserDetail } from "../../types/UserDetail";

interface LocalStorageState {
   token?: string;
   user?: UserDetail;
   isSignedin: boolean;
}

const initialState: LocalStorageState = {
   token: undefined,
   user: undefined,
   isSignedin: false,
};

const tumlaUser = "tumla-user";
const tumlaToken = "tumla-token";

export const localStorageSlice = createSlice({
   name: "localStorage",
   initialState,
   reducers: {
      isSignedIn: (state) => {
         const token = localStorage.getItem(tumlaToken);
         if (token) {
            state.isSignedin = true;
            state.token = token;
         }
      },
      getUser: (state) => {
         const user = localStorage.getItem(tumlaUser);
         state.user = user ? JSON.parse(user) : ({} as UserDetail);
      },
      getToken: (state) => {
         const token = localStorage.getItem(tumlaToken);
         state.token = token ? token : undefined;
      },
      clearAuthenticationTokens: (state) => {
         localStorage.removeItem(tumlaUser);
         state.token = undefined;
         localStorage.removeItem(tumlaToken);
         state.user = undefined;
      },
      setUserToken: (state, action: PayloadAction<string>) => {
         localStorage.setItem(tumlaToken, action.payload.toString());
         state.token = action.payload ? action.payload : undefined;
      },
      setUser: (state, action: PayloadAction<UserDetail>) => {
         localStorage.setItem(tumlaUser, JSON.stringify(action.payload));
      },
   },
});

export const {
   isSignedIn,
   getUser,
   getToken,
   setUserToken,
   setUser,
   clearAuthenticationTokens,
} = localStorageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectIsSignedIn = (state: RootState) =>
   state.localStorage.isSignedin;
export const selectUserDetail = (state: RootState) => state.localStorage.user;
export const selectUserToken = (state: RootState) => state.localStorage.token;

export default localStorageSlice.reducer;
