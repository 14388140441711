import {
   configureStore,
   ThunkAction,
   Action,
   combineReducers,
   getDefaultMiddleware,
} from "@reduxjs/toolkit";
import authenticationReducer from "../features/authentication/authenticationSlice";
import localStorageReducer from "../features/localstorage/localStorageSlice";
import userApprovalReducer from "../features/approval/userApprovalSlice";
import projectsReducer from "../pages/projects/ProjectsSlice";

import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

const rootReducer = combineReducers({
   authentication: authenticationReducer,
   localStorage: localStorageReducer,
   userApproval: userApprovalReducer,
   projectsReducer: projectsReducer,
});

const loggerMiddleware = createLogger();
const middleware = [
   ...getDefaultMiddleware(),
   thunkMiddleware,
   loggerMiddleware,
];
export const store = configureStore({
   reducer: rootReducer,
   middleware: middleware,
   devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
   ReturnType,
   RootState,
   unknown,
   Action<string>
>;
