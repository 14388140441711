import { makeStyles } from "@material-ui/core/styles";

const useListStyles = makeStyles({
   allProjects: {
      padding: "20px",
      paddingBottom: 0,
      paddingRight: "3px",
      fontFamily: "Roboto",
      fontWeight: 700,
      fontStyle: "normal",
      fontSize: "19px",
      color: "#00691D",
   },
   numberOfProjects: {
      padding: "25px",
      paddingBottom: 0,
      paddingLeft: 0,
      fontFamily: "Roboto",
      fontWeight: 700,
      fontStyle: "normal",
      fontSize: "13px",
      color: "#757575",
   },
   content: {
      padding: "12px",
      position: "absolute",
      top: 0,
      height: "100%",
   },
   actionArea: {
      width: "280px",
      height: "190px",
   },
   title: {
      fontSize: "19px",
      fontWeight: 500,
      fontFamily: "Roboto",
      fontStyle: "normal",
      color: "#00691D",
      textTransform: "none",
      textAlign: "left",
   },
   industries: {
      display: "flex",
      flexWrap: "wrap",
      color: "#757575",
      fontWeight: 700,
      fontStyle: "normal",
      fontSize: "15px",
      fontFamily: "Roboto",
      textTransform: "none",
      textAlign: "left",
      paddingTop: "15%",
   },
   createdAt: {
      position: "absolute",
      bottom: "7px",
   },
   moment: {
      textTransform: "none",
   },
   favoriteButton: {
      position: "absolute",
      right: "5%",
      bottom: "2%",
      padding: 0,
   },
   redFavoriteButton: {
      position: "absolute",
      right: "5%",
      bottom: "2%",
      padding: 0,
      color: "red",
   },
   card: {
      position: "relative",
      width: "280px",
   },
   cardUnpublished: {
      position: "relative",
      width: "280px",
      backgroundColor: "#1AAD1913",
      borderBlockColor: "#1AAD19",
      borderInlineColor: "#1AAD19",
   },
   actions: {
      height: "25px",
   },
});

export default useListStyles;
