import React from "react";
import { Route } from "react-router-dom";
import SignInPage from "../components/SignInPage";
import AppMain from "../components/menu/AppMain";
import ForgotPassword from "../components/ForgotPassword";
import "../pages/DrawerContent.css";

export const routes = (
   <div>
      <Route path='/' component={AppMain} />
      <Route exact path='/login' component={SignInPage} />
      <Route exact path='/forgot-password' component={ForgotPassword} />
   </div>
);
