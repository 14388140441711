import {
   Box,
   Button,
   MenuItem,
   Select,
   TextField,
   Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NewProposalDialog from "../NewProposalDialog";
import { useDispatch, useSelector } from "react-redux";
import {
   getFilteredProjects,
   getInitialPublished,
   selectPublishedProjects,
   selectInitialPublished,
} from "../ProjectsSlice";
import useFilterStyles from "./ProjectFilterStyles";
import { selectUser } from "../../../features/authentication/authenticationSlice";

export default function ProjectsFilter() {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getInitialPublished());
   }, [dispatch]);

   const publishedProjects = useSelector(selectPublishedProjects);
   const initialPublished = useSelector(selectInitialPublished);

   const filterActive =  initialPublished.length === publishedProjects.length ? false : true
   const [industriesFilter, setIndustriesFilter] = useState<string[]>([]);
   const [searchFilter, setSearchFilter] = useState<string>("");
   const [typeFilter, setTypeFilter] = useState<string>("all");
   const [sortFilter, setSortFilter] = useState<string>("earliest");

   useEffect(() => {
      if(!filterActive){
         setIndustriesFilter([])
         setSearchFilter("")
         setTypeFilter("all")
         setSortFilter("earliest")
      }
   },[filterActive])

   const userEmail = useSelector(selectUser).email;

   const [newProjectDialogOpen, setNewProjectDialogOpen] = useState<boolean>(
      false
   );

   function handleFilterType(event) {
      setTypeFilter(event.target.value);
      dispatch(
         getFilteredProjects(
            industriesFilter,
            userEmail,
            event.target.value,
            sortFilter,
            searchFilter
         )
      );
   }

   function handleFilterSearch(event) {
      setSearchFilter(event.target.value);
      dispatch(
         getFilteredProjects(
            industriesFilter,
            userEmail,
            typeFilter,
            sortFilter,
            event.target.value
         )
      );
   }

   function handleSortFilter(event) {
      setSortFilter(event.target.value);
      dispatch(
         getFilteredProjects(
            industriesFilter,
            userEmail,
            typeFilter,
            event.target.value,
            searchFilter
         )
      );
   }

   function addFilter(filter) {
      let newArray = [...industriesFilter];
      newArray.push(filter);
      setIndustriesFilter(newArray);
      dispatch(
         getFilteredProjects(
            newArray,
            userEmail,
            typeFilter,
            sortFilter,
            searchFilter
         )
      );
   }

   function deleteFilter(filter) {
      const newArray = industriesFilter.filter((industry) => {
         return industry !== filter;
      });
      setIndustriesFilter(newArray);
      dispatch(
         getFilteredProjects(
            newArray,
            userEmail,
            typeFilter,
            sortFilter,
            searchFilter
         )
      );
   }

   function clearIndustriesFilter() {
      setIndustriesFilter([]);
      dispatch(
         getFilteredProjects(
            [],
            userEmail,
            typeFilter,
            sortFilter,
            searchFilter
         )
      );
   }
   function countIndustries(industry: string) {
      let count = 0;
      publishedProjects.forEach((project) => {
         if (Object.keys(project.industries).includes(industry)) {
            count = count + 1;
         }
      });
      return count;
   }

   function countTypes(type: string) {
      let count = 0;
      initialPublished?.forEach((project) => {
         if (project.type === type) {
            count = count + 1;
         }
      });
      return count;
   }

   const classes = useFilterStyles();
   return (
      <Box display='block'>
         <Box display='flex' flexWrap='wrap' m={2}>
            <Box className={classes.filter}>
               <Typography className={classes.filterTitle}>Search</Typography>
               <TextField
                  variant='outlined'
                  value={searchFilter}
                  onChange={handleFilterSearch}
               ></TextField>
            </Box>
            <Box className={classes.filter}>
               <Typography className={classes.filterTitle}>Type</Typography>
               <Select
                  value={typeFilter}
                  onChange={handleFilterType}
                  variant='outlined'
                  className={classes.filterType}
               >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"Commercial"}>
                     Commercial ({countTypes("Commercial")})
                  </MenuItem>
                  <MenuItem value={"Individual Study Project"}>
                     Individual Study Project (
                     {countTypes("Individual Study Project")})
                  </MenuItem>
                  <MenuItem value={"Startup"}>
                     Startup ({countTypes("Startup")})
                  </MenuItem>
               </Select>
            </Box>
            <Box className={classes.filter}>
               <Typography className={classes.filterTitle}>Sort</Typography>
               <Select
                  className={classes.filterSort}
                  value={sortFilter}
                  onChange={handleSortFilter}
                  variant='outlined'
               >
                  <MenuItem value={"latest"}>Oldest First</MenuItem>
                  <MenuItem value={"earliest"}>Newest First</MenuItem>
               </Select>
            </Box>
            <div className={classes.pt}>
               <Box className={classes.filter}>
                  <Button
                     variant='outlined'
                     onClick={() => setNewProjectDialogOpen(true)}
                     className={classes.newProposal}
                  >
                     Submit new project proposal
                  </Button>
               </Box>
               <NewProposalDialog
                  setDialogOpen={setNewProjectDialogOpen}
                  open={newProjectDialogOpen}
               />
            </div>
         </Box>
         <Box className={classes.industriesBlock}>
            <Typography className={classes.filterTitle}>Industries</Typography>
            <Button
               onClick={() => clearIndustriesFilter()}
               className={
                  industriesFilter.length === 0
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
            >
               All ({initialPublished?.length})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Agriculture & Food")
                     ? deleteFilter("Agriculture & Food")
                     : addFilter("Agriculture & Food")
               }
               className={
                  industriesFilter.includes("Agriculture & Food")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Agriculture & Food") === 0}
            >
               Agriculture & Food ({countIndustries("Agriculture & Food")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Consulting")
                     ? deleteFilter("Consulting")
                     : addFilter("Consulting")
               }
               className={
                  industriesFilter.includes("Consulting")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Consulting") === 0}
            >
               Consulting ({countIndustries("Consulting")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Consumer & Luxury Goods")
                     ? deleteFilter("Consumer & Luxury Goods")
                     : addFilter("Consumer & Luxury Goods")
               }
               className={
                  industriesFilter.includes("Consumer & Luxury Goods")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Consumer & Luxury Goods") === 0}
            >
               Consumer & Luxury Goods (
               {countIndustries("Consumer & Luxury Goods")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Education")
                     ? deleteFilter("Education")
                     : addFilter("Education")
               }
               className={
                  industriesFilter.includes("Education")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Education") === 0}
            >
               Education ({countIndustries("Education")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Energy")
                     ? deleteFilter("Energy")
                     : addFilter("Energy")
               }
               className={
                  industriesFilter.includes("Energy")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Energy") === 0}
            >
               Energy ({countIndustries("Energy")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Finance")
                     ? deleteFilter("Finance")
                     : addFilter("Finance")
               }
               className={
                  industriesFilter.includes("Finance")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Finance") === 0}
            >
               Finance ({countIndustries("Finance")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Fintech")
                     ? deleteFilter("Fintech")
                     : addFilter("Fintech")
               }
               className={
                  industriesFilter.includes("Fintech")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Fintech") === 0}
            >
               Fintech ({countIndustries("Fintech")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Healthcare")
                     ? deleteFilter("Healthcare")
                     : addFilter("Healthcare")
               }
               className={
                  industriesFilter.includes("Healthcare")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Healthcare") === 0}
            >
               Healthcare ({countIndustries("Healthcare")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Industrials")
                     ? deleteFilter("Industrials")
                     : addFilter("Industrials")
               }
               className={
                  industriesFilter.includes("Industrials")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Industrials") === 0}
            >
               Industrials ({countIndustries("Industrials")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Media & Telecom")
                     ? deleteFilter("Media & Telecom")
                     : addFilter("Media & Telecom")
               }
               className={
                  industriesFilter.includes("Media & Telecom")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Media & Telecom") === 0}
            >
               Media & Telecom ({countIndustries("Media & Telecom")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("PE & VC")
                     ? deleteFilter("PE & VC")
                     : addFilter("PE & VC")
               }
               className={
                  industriesFilter.includes("PE & VC")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("PE & VC") === 0}
            >
               PE & VC ({countIndustries("PE & VC")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Retail")
                     ? deleteFilter("Retail")
                     : addFilter("Retail")
               }
               className={
                  industriesFilter.includes("Retail")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Retail") === 0}
            >
               Retail ({countIndustries("Retail")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Social Impact")
                     ? deleteFilter("Social Impact")
                     : addFilter("Social Impact")
               }
               className={
                  industriesFilter.includes("Social Impact")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Social Impact") === 0}
            >
               Social Impact ({countIndustries("Social Impact")})
            </Button>
            <Button
               onClick={() =>
                  industriesFilter.includes("Tech")
                     ? deleteFilter("Tech")
                     : addFilter("Tech")
               }
               className={
                  industriesFilter.includes("Tech")
                     ? classes.selectedButton
                     : classes.nonSelectedButton
               }
               variant='outlined'
               disabled={countIndustries("Tech") === 0}
            >
               Tech ({countIndustries("Tech")})
            </Button>
         </Box>
      </Box>
   );
}
