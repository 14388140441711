import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import firebase from "firebase/app";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "formik-material-ui-pickers";
import {
   Box,
   Button,
   IconButton,
   MenuItem,
   Typography,
} from "@material-ui/core";
import { Select, TextField } from "formik-material-ui";
import DateFnsUtils from "@date-io/date-fns";
import PlacesAutocomplete from "react-places-autocomplete";
import cuid from "cuid";
import {
   getFavoritesProjects,
   getPublishedProjects,
   getUnpublishedProjects,
   setCurrentProject,
   setScenery,
} from "../ProjectsSlice";
import { useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

toast.configure();

const MenuProps = {
   getContentAnchorEl: null,
   anchorOrigin: {
     vertical: "bottom",
     horizontal: "left"
   },
   MenuListProps: {
     tabindex: "1",
     tabIndex: "1"
   }
 };

export default function ProjectEditScenery({
   validationSchema,
   initialValues,
   address,
   setAddress,
   handleSelect,
   currentProject,
   setDialogOpen,
   userData,
   handleSceneryChange,
   updatePublishedState,
   userEmail,
}) {
   const [disabled, setDisabled] = useState<boolean>(false);
   const dispatch = useDispatch();

   return (
      <Formik
         validationSchema={validationSchema}
         initialValues={initialValues}
         onSubmit={async (values) => {
            setDisabled(true);
            values.location = address;
            let newDoc = {};

            newDoc["projectTitle"] = values.projectTitle;
            newDoc["companyName"] = values.companyName;
            newDoc["companyWebsite"] = values.companyWebsite;

            let industriesObj = {};
            values.industries.map(
               (industry) => (industriesObj[industry] = true)
            );
            newDoc["industries"] = industriesObj;

            newDoc["type"] = values.type;
            newDoc["duration"] = values.duration;
            newDoc["effort"] = values.effort;
            newDoc["location"] = values.location;
            newDoc["teamSize"] = values.teamSize;
            newDoc["deliverable"] = values.deliverable;
            newDoc["startDate"] = new Date(values.startDate.toString());
            newDoc["projectDescription"] = values.projectDescription;
            newDoc["projectSponsor"] = {
               email: values.sponsorEmail,
               name: values.sponsorName,
               role: values.sponsorRole,
            };
            newDoc["companyDescription"] = values.companyDescription;

            newDoc["published"] = currentProject?.published;
            if (currentProject?.published) {
               newDoc["firstPublishDate"] = new Date(
                  currentProject?.firstPublishDate.toString()
               );
            }

            newDoc["createdAt"] = new Date(
               currentProject?.createdAt.toString()
            );

            newDoc["comments"] = "";

            if (currentProject?.favorites) {
               newDoc["favorites"] = currentProject.favorites;
            }

            let db = firebase.firestore();
            let projectsRef = db
               .collection("business_projects")
               .doc(currentProject?.id);
            projectsRef
               .set(newDoc)
               .then(function () {
                  setDialogOpen(false);
                  toast.success("Data successfully sent! 🚀🚀", {
                     autoClose: 2000,
                  });
                  if (newDoc["published"]) {
                     dispatch(getPublishedProjects());
                     dispatch(getFavoritesProjects(userEmail));
                  } else {
                     dispatch(getUnpublishedProjects());
                     dispatch(getFavoritesProjects(userEmail));
                  }
                  dispatch(setCurrentProject({}));
                  dispatch(setScenery("view"));
               })
               .catch(function (error) {
                  setDialogOpen(false);
                  toast.error(error.message, { autoClose: 4000 });
               });
         }}
      >
         {({ submitForm, isValid, dirty }) => (
            <>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Form>
                     <Box id='greenHead'>
                        {userData.isInsead && userData.isEditor && (
                           <Box id='topButtonsBox'>
                              <Button
                                 className='topButtons'
                                 variant='outlined'
                                 onClick={handleSceneryChange}
                              >
                                 View
                              </Button>
                              <Button
                                 className='topButtons'
                                 variant='outlined'
                                 onClick={() =>
                                    (window.location.href = `mailto:${currentProject?.projectSponsor?.email}?subject=Business%20projects%20editor%20request`)
                                 }
                              >
                                 Contact Author
                              </Button>
                              <Button
                                 className='topButtons'
                                 variant='outlined'
                                 onClick={updatePublishedState}
                              >
                                 {currentProject?.published
                                    ? "Unpublish"
                                    : "Publish"}
                              </Button>
                              <IconButton
                                 className='topButtons closeIconButton'
                                 onClick={() => setDialogOpen(false)}
                              >
                                 <CloseIcon />
                              </IconButton>
                           </Box>
                        )}

                        <Box id='greenHeadMainChild' margin={0} display='block'>
                           <Box id='projectTitleBox' display='flex'>
                              <Field
                                 component={TextField}
                                 name='projectTitle'
                                 type='text'
                                 variant='outlined'
                                 id='projectTitle'
                                 placeholder='Project Title*'
                              />
                           </Box>
                           <Box
                              id='companyBox'
                              margin={0}
                              display='flex'
                              pr={2}
                              pl={2}
                           >
                              <Field
                                 component={TextField}
                                 name='companyName'
                                 type='text'
                                 variant='outlined'
                                 id='companyName'
                                 placeholder='Company Name (if involved)'
                              />{" "}
                              <Field
                                 component={TextField}
                                 name='companyWebsite'
                                 type='text'
                                 variant='outlined'
                                 id='companyWebsite'
                                 placeholder='Company Website'
                              />
                           </Box>
                        </Box>
                     </Box>
                     <Box
                        id='opacityGreen'
                        style={{ display: "flex", flexWrap: "wrap" }}
                     >
                        <Box>
                           <Typography className='opacityGreenTitle'>
                              Industries (one or more)*
                           </Typography>
                           <Field
                              component={Select}
                              multiple={true}
                              MenuProps={{...MenuProps, disableAutoFocusItem: true }}
                              name='industries'
                              variant='outlined'
                              type='text'
                              className='opacityGreenField'
                           >
                              <MenuItem value='Agriculture & Food'>
                                 Agriculture & Food
                              </MenuItem>
                              <MenuItem value='Consulting'>Consulting</MenuItem>
                              <MenuItem value='Consumer & Luxury Goods'>
                                 Consumer & Luxury Goods
                              </MenuItem>
                              <MenuItem value='Education'>Education</MenuItem>
                              <MenuItem value='Energy'>Energy</MenuItem>
                              <MenuItem value='Finance'>Finance</MenuItem>
                              <MenuItem value='Fintech'>Fintech</MenuItem>
                              <MenuItem value='Healthcare'>Healthcare</MenuItem>
                              <MenuItem value='Industrials'>
                                 Industrials
                              </MenuItem>
                              <MenuItem value='Media & Telecom'>
                                 Media & Telecom
                              </MenuItem>
                              <MenuItem value='PE & VC'>PE & VC</MenuItem>
                              <MenuItem value='Retail'>Retail</MenuItem>
                              <MenuItem value='Social Impact'>
                                 Social Impact
                              </MenuItem>
                              <MenuItem value='Tech'>Tech</MenuItem>
                              <MenuItem value='Start-ups'>Start-ups</MenuItem>
                           </Field>
                        </Box>
                        <Box>
                           <Typography className='opacityGreenTitle'>
                              Type*
                           </Typography>
                           <Field
                              component={TextField}
                              select
                              name='type'
                              variant='outlined'
                              type='text'
                              className='opacityGreenField'
                           >
                              <MenuItem value='Individual Study Project'>
                                 Individual Study Project
                              </MenuItem>
                              <MenuItem value='Commercial'>Commercial</MenuItem>
                              <MenuItem value='Startup'>Startup</MenuItem>
                           </Field>
                        </Box>
                        <Box>
                           <Typography className='opacityGreenTitle'>
                              Duration (weeks)*
                           </Typography>
                           <Field
                              component={TextField}
                              name='duration'
                              type='number'
                              variant='outlined'
                              className='opacityGreenField'
                           />
                        </Box>
                        <Box>
                           <Typography className='opacityGreenTitle'>
                              Effort
                           </Typography>
                           <Field
                              component={TextField}
                              select
                              name='effort'
                              variant='outlined'
                              type='text'
                              className='opacityGreenField'
                           >
                              <MenuItem value='1-2 hours per week'>
                                 1-2 hours per week
                              </MenuItem>
                              <MenuItem value='2-5 hours per week'>
                                 2-5 hours per week
                              </MenuItem>
                              <MenuItem value='5-10 hours per week'>
                                 5-10 hours per week
                              </MenuItem>
                              <MenuItem value='10+ hours per week'>
                                 10+ hours per week
                              </MenuItem>
                           </Field>
                        </Box>

                        <Box>
                           <Typography className='opacityGreenTitle'>
                              Location*
                           </Typography>{" "}
                           <PlacesAutocomplete
                              value={address || "Virtual"}
                              onChange={setAddress}
                              onSelect={handleSelect}
                           >
                              {({
                                 getInputProps,
                                 suggestions,
                                 getSuggestionItemProps,
                                 loading,
                              }) => (
                                 <div>
                                    <Field
                                       {...getInputProps()}
                                       component={TextField}
                                       name='location'
                                       type='text'
                                       variant='outlined'
                                       placeholder='Location'
                                       className='opacityGreenField'
                                    />{" "}
                                    <div>
                                       {loading ? <div>...loading</div> : null}

                                       {suggestions.map((suggestion) => {
                                          const style = {
                                             backgroundColor: suggestion.active
                                                ? "#27e650"
                                                : "#fff",
                                             cursor: "pointer",
                                          };

                                          return (
                                             <div
                                                {...getSuggestionItemProps(
                                                   suggestion,
                                                   { style }
                                                )}
                                                key={cuid()}
                                             >
                                                <span>
                                                   {suggestion.description}
                                                </span>
                                             </div>
                                          );
                                       })}
                                    </div>
                                 </div>
                              )}
                           </PlacesAutocomplete>
                        </Box>
                        <Box>
                           <Typography className='opacityGreenTitle'>
                              Team Size*
                           </Typography>
                           <Field
                              component={TextField}
                              name='teamSize'
                              type='text'
                              variant='outlined'
                              placeholder='Team Size'
                              className='opacityGreenField'
                           />{" "}
                        </Box>

                        <Box>
                           <Typography className='opacityGreenTitle'>
                              Deliverable*
                           </Typography>
                           <Field
                              component={TextField}
                              select
                              name='deliverable'
                              variant='outlined'
                              type='text'
                              className='opacityGreenField'
                           >
                              <MenuItem value='Strategy deck'>
                                 Strategy deck
                              </MenuItem>
                              <MenuItem value='Financial Model'>
                                 Financial Model
                              </MenuItem>
                              <MenuItem value='Plan for implementation of strategy'>
                                 Plan for implementation of strategy
                              </MenuItem>
                              <MenuItem value='Conference or meeting planned'>
                                 Conference or meeting planned
                              </MenuItem>
                              <MenuItem value='New Article of publication'>
                                 New Article of publication
                              </MenuItem>
                              <MenuItem value='Chapter(s) of a book'>
                                 Chapter(s) of a book
                              </MenuItem>
                           </Field>
                        </Box>
                        <Box>
                           <Typography className='opacityGreenTitle'>
                              Start Date
                           </Typography>
                           <Field
                              component={DatePicker}
                              name='startDate'
                              inputVariant='outlined'
                              id='startDate'
                              className='opacityGreenField'
                           />
                        </Box>
                     </Box>
                     <Box display='flex'>
                        <Box style={{ width: "65%" }}>
                           <Typography id='projectDescriptionTitle'>
                              Project Description*
                           </Typography>
                           <Field
                              as='textarea'
                              name='projectDescription'
                              type='text'
                              variant='outlined'
                              id='projectDescription'
                           />
                        </Box>

                        <Box
                           display='inline-grid'
                           style={{
                              marginTop: "1.5%",
                              marginLeft: "10%",
                           }}
                        >
                           <Typography>Project Sponsor</Typography>
                           <Field
                              component={TextField}
                              name='sponsorName'
                              type='text'
                              variant='outlined'
                              placeholder='Sponsor name*'
                           />
                           <Field
                              component={TextField}
                              name='sponsorRole'
                              type='text'
                              variant='outlined'
                              placeholder='Sponsor role'
                           />
                           <Field
                              component={TextField}
                              name='sponsorEmail'
                              type='text'
                              variant='outlined'
                              placeholder='Sponsor email*'
                           />
                        </Box>
                     </Box>

                     <Box id='companyDescriptionBox'>
                        <Box style={{ width: "66%" }}>
                           <Typography id='companyDescriptionTitle'>
                              Company Description (if involved)
                           </Typography>
                           <Field
                              as='textarea'
                              name='companyDescription'
                              type='text'
                              variant='outlined'
                              id='companyDescription'
                           />
                        </Box>
                        <Box alignSelf='flex-end' id='submitButtonBox'>
                           <Button
                              id='submitButton'
                              variant='outlined'
                              color='primary'
                              disabled={!dirty || !isValid || disabled}
                              onClick={submitForm}
                           >
                              Submit
                           </Button>
                        </Box>
                     </Box>
                  </Form>
               </MuiPickersUtilsProvider>
            </>
         )}
      </Formik>
   );
}
