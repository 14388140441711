import { makeStyles } from "@material-ui/core/styles";

const useFilterStyles = makeStyles({
   newProposal: {
      textTransform: "none",
      height: "56px",
      color: "#00764B",
      borderColor: "#00764B",
      bottom: 0,
   },
   filter: {
      paddingRight: "20px",
   },
   filterSort: {
      width: "170px",
   },
   perList: {
      width: "120px",
   },
   filterType: {
      width: "190px",
   },
   favoriteFilter: {
      width: "150px",
   },
   pt: {
      paddingTop: "24px",
   },
   filterTitle: {
      color: "#333333",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "24px",
   },
   industriesBlock: {
      display: "block",
      padding: "20px",
      paddingTop: 0,
   },
   nonSelectedButton: {
      color: "#004A30",
   },
   selectedButton: {
      backgroundColor: "#004A30",
      color: "white",
   },
});

export default useFilterStyles;
