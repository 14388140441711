import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import { routes } from "./routing/routes";
import { routeHistory } from "./routing/history";
import firebase from "firebase/app";

const firebaseDevConfig = {
   apiKey: "AIzaSyBv5Y9lSlrnZ70nr1W7Pkr0Neczj2EkGG4",
   authDomain: "tumla2020.firebaseapp.com",
   databaseURL: "https://tumla2020.firebaseio.com",
   projectId: "tumla2020",
   storageBucket: "tumla2020.appspot.com",
   messagingSenderId: "272818466053",
   appId: "1:272818466053:web:a3ea09a85b9f86523e1b6c",
   measurementId: "G-7ENYR5FRX3",
};
const firebaseProdConfig = {
   apiKey: "AIzaSyCTl1ywVxzQOSdIcmNzLVTCDNqeGeGo4Zw",
   authDomain: "tumla-prod.firebaseapp.com",
   databaseURL: "https://tumla-prod.firebaseio.com",
   projectId: "tumla-prod",
   storageBucket: "tumla-prod.appspot.com",
   messagingSenderId: "558264199257",
   appId: "1:558264199257:web:f5973b450807ff0f342948",
   measurementId: "G-ZXFZZK6HTD",
};

const firebaseConfig =
   process.env.REACT_APP_STAGE === "production"
      ? firebaseProdConfig
      : firebaseDevConfig;

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
   <Provider store={store}>
      <Router history={routeHistory}>{routes}</Router>
   </Provider>,
   document.getElementById("root")
);

serviceWorker.unregister();
