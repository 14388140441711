import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import firebase from "firebase/app";
import "firebase/functions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import "../ProjectsDialog.css";
import * as Yup from "yup";
import {
   getFavoritesProjects,
   getInitialPublished,
   getPublishedProjects,
   getUnpublishedProjects,
   selectCurrentProject,
   selectFavoritesPage,
   selectFavoritesPagination,
   selectFavoritesProjects,
   selectPublishedPage,
   selectPublishedPagination,
   selectPublishedProjects,
   selectScenery,
   selectUnpublishedPage,
   selectUnpublishedPagination,
   selectUnpublishedProjects,
   setCurrentProject,
   setFavoritesPage,
   setPublishedPage,
   setScenery,
   setUnpublishedPage,
} from "../ProjectsSlice";
import ProjectEditScenery from "./ProjectEditScenery";
import ProjectViewScenery from "./ProjectViewScenery";
import Values from "./Types";

toast.configure();

export default function ProjectDetailDialog({
   id,
   open,
   setDialogOpen,
   userData,
   userEmail,
   favoriteOpen,
   projectsPerList,
}) {
   const [currentId, setCurrentId] = useState<string>();
   const [currentIndex, setCurrentIndex] = useState<number>();
   const [comments, setComments] = useState<string>("");
   const [industries, setIndustries] = useState<string[]>();
   const [address, setAddress] = useState<string>("");

   const dispatch = useDispatch();
   const favoritesProjects = useSelector(selectFavoritesProjects);
   const publishedProjects = useSelector(selectPublishedProjects);
   const unpublishedProjects = useSelector(selectUnpublishedProjects);
   const currentProject = useSelector(selectCurrentProject);
   const scenery = useSelector(selectScenery);

   const publishedPagination = useSelector(selectPublishedPagination);
   const unpublishedPagination = useSelector(selectUnpublishedPagination);
   const favoritesPagination = useSelector(selectFavoritesPagination);

   const publishedPage = useSelector(selectPublishedPage);
   const unpublishedPage = useSelector(selectUnpublishedPage);
   const favoritesPage = useSelector(selectFavoritesPage);

   useEffect(() => {
      const currentUnpublishedProject = publishedProjects.filter(
         (project) => project.id === currentId
      );

      const currentPublishedProject = unpublishedProjects.filter(
         (project) => project.id === currentId
      );

      const currentFavoriteProject = favoritesProjects.filter(
         (project) => project.id === currentId
      );

      if (favoriteOpen && currentFavoriteProject[0]) {
         dispatch(
            setCurrentProject(
               currentFavoriteProject.length > 0
                  ? currentFavoriteProject[0]
                  : null
            )
         );
      } else if (
         currentPublishedProject.length > 0 ||
         currentUnpublishedProject.length > 0
      ) {
         dispatch(
            setCurrentProject(
               currentUnpublishedProject.length > 0
                  ? currentUnpublishedProject[0]
                  : currentPublishedProject[0]
            )
         );
      }
   }, [
      dispatch,
      publishedProjects,
      unpublishedProjects,
      currentId,
      favoriteOpen,
      favoritesProjects,
   ]);

   useEffect(() => {
      setAddress(currentProject?.location);
      setComments(currentProject?.comments);
      setCurrentId(currentProject?.id || id);
      setIndustries(Object.keys(currentProject?.industries || {}));
   }, [currentProject, id]);

   useEffect(() => {
      const publishedIndex = publishedProjects.indexOf(currentProject);
      const unpublishedIndex = unpublishedProjects.indexOf(currentProject);
      const favoriteIndex = favoritesProjects.indexOf(currentProject);

      if (favoriteOpen) {
         setCurrentIndex(favoriteIndex !== -1 ? favoriteIndex : null);
      } else {
         setCurrentIndex(
            publishedIndex !== -1 ? publishedIndex : unpublishedIndex
         );
      }
   }, [
      currentProject,
      publishedProjects,
      unpublishedProjects,
      currentIndex,
      favoritesProjects,
      favoriteOpen,
   ]);

   function changeCurrentProjectPlus() {
      if (favoriteOpen) {
         if (currentIndex !== favoritesProjects.length - 1) {
            dispatch(setCurrentProject(favoritesProjects[currentIndex + 1]));
            setCurrentId(favoritesProjects[currentIndex + 1].id);
            if (currentIndex === favoritesPagination.length - 1) {
               dispatch(setFavoritesPage(favoritesPage + 1));
            }
         }
      } else if (currentProject.published) {
         if (currentIndex !== publishedProjects.length - 1) {
            dispatch(setCurrentProject(publishedProjects[currentIndex + 1]));
            setCurrentId(publishedProjects[currentIndex + 1].id);
            if (currentIndex === publishedPagination.length - 1) {
               dispatch(setPublishedPage(publishedPage + 1));
            }
         }
      } else if (!currentProject.published) {
         if (currentIndex !== unpublishedProjects.length - 1) {
            dispatch(setCurrentProject(unpublishedProjects[currentIndex + 1]));
            setCurrentId(unpublishedProjects[currentIndex + 1].id);
            if (currentIndex === unpublishedPagination.length - 1) {
               dispatch(setUnpublishedPage(unpublishedPage + 1));
            }
         }
      }
   }

   function changeCurrentProjectMinus() {
      if (favoriteOpen) {
         if (currentIndex !== 0) {
            dispatch(setCurrentProject(favoritesProjects[currentIndex - 1]));
            setCurrentId(favoritesProjects[currentIndex - 1].id);
            if (favoritesPage > 1 && currentIndex - projectsPerList === 0) {
               dispatch(setFavoritesPage(favoritesPage - 1));
            }
         }
      } else if (currentIndex !== 0) {
         if (currentProject.published) {
            dispatch(setCurrentProject(publishedProjects[currentIndex - 1]));
            setCurrentId(publishedProjects[currentIndex - 1].id);
            if (publishedPage > 1 && currentIndex - projectsPerList === 0) {
               dispatch(setPublishedPage(publishedPage - 1));
            }
         } else {
            dispatch(setCurrentProject(unpublishedProjects[currentIndex - 1]));
            setCurrentId(unpublishedProjects[currentIndex - 1].id);
            if (unpublishedPage > 1 && currentIndex - projectsPerList === 0) {
               dispatch(setUnpublishedPage(unpublishedPage - 1));
            }
         }
      }
   }

   function handleSceneryChange() {
      scenery === "view"
         ? dispatch(setScenery("edit"))
         : dispatch(setScenery("view"));
   }

   function updatePublishedState() {
      const newPublishedState = !currentProject.published;
      const newPublishDate = !currentProject?.firstPublishDate && newPublishedState ? new Date() : new Date(currentProject?.firstPublishDate);
      console.log(!newPublishDate, 11111);
      let db = firebase.firestore();
      let projectsRef = db
         .collection("business_projects")
         .doc(currentProject.id);
      projectsRef
         .update({
            published: newPublishedState,
            firstPublishDate: newPublishDate,
         })
         .then(function () {
            setDialogOpen(false);
            toast.success("Data successfully sent! 🚀🚀", { autoClose: 2000 });
            if (publishedPagination.length === 1 && newPublishedState) {
               dispatch(setPublishedPage(publishedPage - 1));
            }
            if (unpublishedPagination.length === 1 && !newPublishedState) {
               dispatch(setUnpublishedPage(unpublishedPage - 1));
            }
            dispatch(setScenery("view"));
            dispatch(setCurrentProject({}));
            dispatch(getUnpublishedProjects());
            dispatch(getPublishedProjects());
            dispatch(getFavoritesProjects(userEmail));
            dispatch(getInitialPublished());
         })
         .catch(function (error) {
            setDialogOpen(false);
            toast.error(error.message, { autoClose: 4000 });
         });
   }

   const handleSelect = (value) => {
      setAddress(value);
   };

   function submitComments() {
      let db = firebase.firestore();
      let projectsRef = db
         .collection("business_projects")
         .doc(currentProject.id);
      projectsRef
         .update({
            comments: comments,
         })
         .then(function () {
            setDialogOpen(false);
            toast.success("Data successfully sent! 🚀🚀", { autoClose: 2000 });
            if (currentProject.published) {
               dispatch(getPublishedProjects());
               dispatch(getFavoritesProjects(userEmail));
            } else {
               dispatch(getUnpublishedProjects());
               dispatch(getFavoritesProjects(userEmail));
            }
         })
         .catch(function (error) {
            setDialogOpen(false);
            toast.error(error.message, { autoClose: 4000 });
         });
   }

   function handleDialogClose() {
      const className =
         document.getElementById("submitButton-form")?.className ||
         document.getElementById("submitButton")?.className;
      const materialReturn = className?.substr(className.length - 8);
      const buttonDisabled = materialReturn === "disabled" ? true : false;

      if (materialReturn === undefined) {
         setDialogOpen(false);
         dispatch(setScenery("view"));
         dispatch(setCurrentProject({}));
      } else if (buttonDisabled) {
         setDialogOpen(false);
         dispatch(setScenery("view"));
         dispatch(setCurrentProject({}));
      } else {
         let conf = window.confirm(
            "You have unsaved changes, are you sure you want to leave?"
         );
         if (conf) {
            setDialogOpen(false);
            dispatch(setScenery("view"));
            dispatch(setCurrentProject({}));
         } else {
            return;
         }
      }
   }

   const initialValues: Values = {
      sponsorName: currentProject?.projectSponsor?.name || "",
      sponsorRole: currentProject?.projectSponsor?.role || "",
      sponsorEmail: currentProject?.projectSponsor?.email || "",
      projectTitle: currentProject?.projectTitle || "",
      projectDescription: currentProject?.projectDescription || "",
      startDate: currentProject?.startDate || new Date(),
      industries: industries || [],
      type: currentProject?.type || "Commercial",
      duration: currentProject?.duration || 1,
      effort: currentProject?.effort || "1-2 hours per week",
      location: currentProject?.location,
      teamSize: currentProject?.teamSize || "",
      deliverable: currentProject?.deliverable || "Strategy deck",
      companyName: currentProject?.companyName || "",
      companyWebsite: currentProject?.companyWebsite || "",
      companyDescription: currentProject?.companyDescription || "",
   };

   const validationSchema = Yup.object().shape({
      projectTitle: Yup.string().required("Required Field"),
      industries: Yup.array().required(
         "You need to choose at least one industry"
      ),
      type: Yup.string().required("Required Field"),
      teamSize: Yup.string().max(20).required("Required Field"),
      deliverable: Yup.string().required("Required Field"),
      projectDescription: Yup.string().required("Required Field"),
      sponsorName: Yup.string().required("Required Field"),
      sponsorEmail: Yup.string().email().required("Required Field"),
      duration: Yup.number().min(1).max(15).required(),
      companyWebsite: Yup.string().matches(
         /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
         "Enter correct url!"
      ),
   });

   return (
      <Dialog open={open} onClose={handleDialogClose}>
         <DialogContent>
            {scenery === "edit" && userData?.isInsead && userData?.isEditor && (
               <ProjectEditScenery
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  address={address}
                  setAddress={setAddress}
                  handleSelect={handleSelect}
                  currentProject={currentProject}
                  setDialogOpen={setDialogOpen}
                  userData={userData}
                  handleSceneryChange={handleSceneryChange}
                  updatePublishedState={updatePublishedState}
                  userEmail={userEmail}
               />
            )}
            {scenery === "view" && userData?.isInsead && (
               <ProjectViewScenery
                  userData={userData}
                  currentProject={currentProject}
                  handleSceneryChange={handleSceneryChange}
                  updatePublishedState={updatePublishedState}
                  industries={industries}
                  changeCurrentProjectPlus={changeCurrentProjectPlus}
                  changeCurrentProjectMinus={changeCurrentProjectMinus}
                  comments={comments}
                  setComments={setComments}
                  setDialogOpen={setDialogOpen}
                  submitComments={submitComments}
                  currentIndex={currentIndex}
                  favoriteOpen={favoriteOpen}
               />
            )}
         </DialogContent>
      </Dialog>
   );
}
